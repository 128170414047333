import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TotalItemsInCartQueryVariables = Types.Exact<{
  finality: Types.FinalityKind;
  plan?: Types.InputMaybe<Types.Scalars['ID']>;
  createIfNotExists?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type TotalItemsInCartQuery = { myCart?: { id: string; itemsNumber: number } | null };

export const TotalItemsInCartDocument = gql`
  query TotalItemsInCart($finality: FinalityKind!, $plan: ID, $createIfNotExists: Boolean = false) {
    myCart(finality: $finality, plan: $plan, createIfNotExists: $createIfNotExists) {
      id
      itemsNumber
    }
  }
`;

/**
 * __useTotalItemsInCartQuery__
 *
 * To run a query within a React component, call `useTotalItemsInCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalItemsInCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalItemsInCartQuery({
 *   variables: {
 *      finality: // value for 'finality'
 *      plan: // value for 'plan'
 *      createIfNotExists: // value for 'createIfNotExists'
 *   },
 * });
 */
export function useTotalItemsInCartQuery(
  baseOptions: Apollo.QueryHookOptions<TotalItemsInCartQuery, TotalItemsInCartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TotalItemsInCartQuery, TotalItemsInCartQueryVariables>(
    TotalItemsInCartDocument,
    options,
  );
}
export function useTotalItemsInCartLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TotalItemsInCartQuery, TotalItemsInCartQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TotalItemsInCartQuery, TotalItemsInCartQueryVariables>(
    TotalItemsInCartDocument,
    options,
  );
}
export type TotalItemsInCartQueryHookResult = ReturnType<typeof useTotalItemsInCartQuery>;
export type TotalItemsInCartLazyQueryHookResult = ReturnType<typeof useTotalItemsInCartLazyQuery>;
export type TotalItemsInCartQueryResult = Apollo.QueryResult<
  TotalItemsInCartQuery,
  TotalItemsInCartQueryVariables
>;
