import { AppBarProps } from '@mui/material';
import { Footer } from '@web/common/layout/footer';
import { IndexToolbar } from '@web/common/layout/index-toolbar';
import { FC, ReactNode } from 'react';
import styled from '@emotion/styled';
import { IndexBottomNav } from '@web/common/layout/index-bottom-nav';

const StyledMain = styled.main`
  min-height: calc(100vh - 64px);
`;

export interface IndexLayoutProps {
  hasFooter?: boolean;
  hasBottomNav?: boolean;
  position?: AppBarProps['position'];
  bottomNavChildren?: ReactNode;
}

export const IndexLayout: FC<IndexLayoutProps> = ({
  children,
  hasFooter = true,
  hasBottomNav = true,
  position = 'relative',
  bottomNavChildren,
}) => {
  return (
    <>
      <IndexToolbar position={position} />
      <IndexBottomNav hasBottomNav={hasBottomNav} bottomNavChildren={bottomNavChildren} />
      <StyledMain>{children}</StyledMain>
      {hasFooter && <Footer />}
    </>
  );
};
