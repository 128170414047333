import { MenuRoute } from '@blb-ventures/react-components';
import styled from '@emotion/styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import StarIcon from '@mui/icons-material/Star';
import { Box, DrawerProps, IconButton, Link, Paper, Typography } from '@mui/material';
import { CartBadgeIcon } from '@web/cart/components/cart-badge-icon';
import { SmartMenu } from '@web/common/components/navigation/smart-menu';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { SafeAreaDrawer } from '@web/common/components/safe-area-drawer';
import {
  blogUrl,
  contentCenterUrl,
  knowMore,
  supportUrl,
} from '@web/common/constants/external-urls';
import { useMeQuery } from 'core/lib/graphql/_gen/me';
import { FC, useMemo } from 'react';
import { ProfileMenu } from './profile-menu';
import { blogIcon, produtoresIcon, storeIcon, supportIcon } from './sidebar-icons';

interface IndexSidebarMenuProps extends DrawerProps {
  open?: boolean;
  onClose: () => void;
  onOpenSignIn: () => void;
}

const SignInPaper = styled(Paper)`
  margin-bottom: ${props => props.theme.spacing(3)};
`;

const SidebarContainer = styled.div`
  width: 360px;
  max-width: 100%;
  padding: ${props => props.theme.spacing(2)};
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPaper = styled(Paper)`
  background: ${props => props.theme.palette.primary.main};
  width: 100%;
  padding: ${props => props.theme.spacing(2)};
  color: white;
`;

const NavIcon = styled(OptimizedImage)`
  touch-action: none;
`;

export const IndexSidebarMenu: FC<IndexSidebarMenuProps> = ({
  open,
  onClose,
  onOpenSignIn,
  ...props
}) => {
  const meQ = useMeQuery();
  const isSubscriber = useMemo(() => meQ.data?.me?.isSubscriber ?? false, [meQ.data]);
  const menuItems: MenuRoute[] = useMemo(
    () => [
      { label: 'Loja', url: '/loja', icon: <NavIcon src={storeIcon} ext="svg" include /> },
      { label: 'Carrinho de compras', url: '/loja/carrinho', icon: <CartBadgeIcon /> },
      {
        label: 'Saiba mais',
        url: knowMore,
        external: true,
        icon: <StarIcon />,
        onClick: onClose,
      },
      {
        label: 'Central de conteúdos',
        url: contentCenterUrl,
        external: true,
        icon: <PhoneIphoneIcon />,
        onClick: onClose,
      },
      {
        label: 'Produtores',
        url: '/produtores',
        icon: <NavIcon src={produtoresIcon} ext="svg" include />,
      },
      {
        label: 'Blog',
        url: blogUrl,
        external: true,
        icon: <NavIcon src={blogIcon} ext="svg" include />,
      },
      {
        label: 'Fale conosco',
        url: supportUrl,
        external: true,
        icon: <NavIcon src={supportIcon} ext="svg" include />,
      },
    ],
    [onClose],
  );
  return (
    <SafeAreaDrawer open={open} anchor="right" onClose={onClose} {...props}>
      <SidebarContainer>
        <SidebarHeader>
          <Typography variant="h4">Menu</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </SidebarHeader>
        <SignInPaper>
          <ProfileMenu onOpenSignIn={onOpenSignIn} onMenuItemClick={onClose} />
        </SignInPaper>
        {!isSubscriber && (
          <Box mb={3}>
            <Link underline="none" href="/#assine" onClick={onClose}>
              <StyledPaper>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <div>
                    <Typography variant="subtitle1">Assine o clube</Typography>
                    <Typography variant="caption">
                      Um café fresco e diferente todos os meses
                    </Typography>
                  </div>
                  <ArrowForwardIcon color="inherit" />
                </Box>
              </StyledPaper>
            </Link>
          </Box>
        )}
        <SmartMenu menuItems={menuItems} hasDivider />
      </SidebarContainer>
    </SafeAreaDrawer>
  );
};
