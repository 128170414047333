import * as Types from '../../types';

import { gql } from '@apollo/client';
import { MutationErrorFieldsFragmentDoc } from '../../fragments/_gen/mutation-error.fragment';
import { UserFieldsFragmentDoc } from '../../fragments/_gen/user.fragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserUpdateMutationVariables = Types.Exact<{
  input: Types.UserUpdateMutationInput;
}>;

export type UserUpdateMutation = {
  userUpdate?: {
    errors?: Array<{ field?: string | null; message?: string | null }> | null;
    user?: {
      id: string;
      isStaff: boolean;
      username: string;
      fullName?: string | null;
      email?: string | null;
      doc?: string | null;
      phone?: string | null;
      birthday?: Date | null;
      gender?: Types.UserGender | null;
      mgmCode: string;
      isSubscriber: boolean;
      kind?: Types.PersonKind | null;
      fancyName?: string | null;
      cityRegistration?: string | null;
      stateRegistration?: string | null;
      isLocalDeliverer?: boolean | null;
      avatar?: { url: string; alt?: string | null } | null;
      currentSubscription?: {
        id: string;
        subtotal: number;
        status?: Types.SubscriptionStatus | null;
        total: number;
        nextCycle: Date;
        nextCycleChange?: Date | null;
        nextCharge?: Date | null;
        nextDate?: Date | null;
        freightDays: number;
        freight: number;
        freightKind?: Types.ShippingKind | null;
        createdAt: Date;
        updatedAt: Date;
        kind?: Types.SubscriptionKind | null;
        discount: number;
        cancelOnNextCycle: boolean;
        pendingInvoices: Array<{
          id: string;
          status: Types.InvoiceStatus;
          chargeInfo?: {
            paymentMethodKind?: Types.PaymentMethod | null;
            invoicePdf?: string | null;
            bankSlipBarcode?: string | null;
            pixQrcode?: string | null;
          } | null;
        }>;
        items: Array<{
          id: string;
          price: number;
          quantity: number;
          discount: number;
          subtotal: number;
          total: number;
          product: {
            id: string;
            name: string;
            quantityStr?: string | null;
            originalPrice?: number | null;
            price: number;
            kind: Types.ProductKind;
            coffeeKind?: Types.ProductCoffeeKind | null;
            images: Array<{ id: string; file?: { url: string; alt?: string | null } | null }>;
          };
          gridOptionValue?: {
            id: string;
            name: string;
            option: { id: string; name: string };
          } | null;
          productGrid?: {
            stock: number;
            value: { id: string; name: string; option: { id: string; name: string } };
            image?: { url: string; alt?: string | null } | null;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export type UserAvatarUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  avatar: Types.Scalars['UploadType'];
}>;

export type UserAvatarUpdateMutation = {
  userUpdate?: {
    errors?: Array<{ field?: string | null; message?: string | null }> | null;
    user?: {
      id: string;
      isStaff: boolean;
      username: string;
      fullName?: string | null;
      email?: string | null;
      doc?: string | null;
      phone?: string | null;
      birthday?: Date | null;
      gender?: Types.UserGender | null;
      mgmCode: string;
      isSubscriber: boolean;
      kind?: Types.PersonKind | null;
      fancyName?: string | null;
      cityRegistration?: string | null;
      stateRegistration?: string | null;
      isLocalDeliverer?: boolean | null;
      avatar?: { url: string; alt?: string | null } | null;
      currentSubscription?: {
        id: string;
        subtotal: number;
        status?: Types.SubscriptionStatus | null;
        total: number;
        nextCycle: Date;
        nextCycleChange?: Date | null;
        nextCharge?: Date | null;
        nextDate?: Date | null;
        freightDays: number;
        freight: number;
        freightKind?: Types.ShippingKind | null;
        createdAt: Date;
        updatedAt: Date;
        kind?: Types.SubscriptionKind | null;
        discount: number;
        cancelOnNextCycle: boolean;
        pendingInvoices: Array<{
          id: string;
          status: Types.InvoiceStatus;
          chargeInfo?: {
            paymentMethodKind?: Types.PaymentMethod | null;
            invoicePdf?: string | null;
            bankSlipBarcode?: string | null;
            pixQrcode?: string | null;
          } | null;
        }>;
        items: Array<{
          id: string;
          price: number;
          quantity: number;
          discount: number;
          subtotal: number;
          total: number;
          product: {
            id: string;
            name: string;
            quantityStr?: string | null;
            originalPrice?: number | null;
            price: number;
            kind: Types.ProductKind;
            coffeeKind?: Types.ProductCoffeeKind | null;
            images: Array<{ id: string; file?: { url: string; alt?: string | null } | null }>;
          };
          gridOptionValue?: {
            id: string;
            name: string;
            option: { id: string; name: string };
          } | null;
          productGrid?: {
            stock: number;
            value: { id: string; name: string; option: { id: string; name: string } };
            image?: { url: string; alt?: string | null } | null;
          } | null;
        }>;
      } | null;
    } | null;
  } | null;
};

export const UserUpdateDocument = gql`
  mutation UserUpdate($input: UserUpdateMutationInput!) {
    userUpdate(input: $input) {
      errors {
        ...mutationErrorFields
      }
      user {
        ...userFields
      }
    }
  }
  ${MutationErrorFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export type UserUpdateMutationFn = Apollo.MutationFunction<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(
    UserUpdateDocument,
    options,
  );
}
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export const UserAvatarUpdateDocument = gql`
  mutation UserAvatarUpdate($id: ID!, $avatar: UploadType!) {
    userUpdate(input: { id: $id, avatar: $avatar }) {
      errors {
        ...mutationErrorFields
      }
      user {
        ...userFields
      }
    }
  }
  ${MutationErrorFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export type UserAvatarUpdateMutationFn = Apollo.MutationFunction<
  UserAvatarUpdateMutation,
  UserAvatarUpdateMutationVariables
>;

/**
 * __useUserAvatarUpdateMutation__
 *
 * To run a mutation, you first call `useUserAvatarUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserAvatarUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userAvatarUpdateMutation, { data, loading, error }] = useUserAvatarUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUserAvatarUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserAvatarUpdateMutation,
    UserAvatarUpdateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserAvatarUpdateMutation, UserAvatarUpdateMutationVariables>(
    UserAvatarUpdateDocument,
    options,
  );
}
export type UserAvatarUpdateMutationHookResult = ReturnType<typeof useUserAvatarUpdateMutation>;
export type UserAvatarUpdateMutationResult = Apollo.MutationResult<UserAvatarUpdateMutation>;
export type UserAvatarUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserAvatarUpdateMutation,
  UserAvatarUpdateMutationVariables
>;
