import { useState } from 'react';

export const useDialogs = <T extends string>(
  initialOpen: T | null = null,
  initialLoad: Partial<Record<T, boolean>> = {},
) => {
  const [open, setOpen] = useState<T | null>(initialOpen);
  const [load, setLoad] = useState<Partial<Record<T, boolean>>>(initialLoad);

  const openDialog = (dialog: T) => {
    setOpen(dialog);
    if (dialog != null) {
      setLoad(prevState => ({
        ...prevState,
        [dialog]: true,
      }));
    }
  };
  const closeDialog = () => setOpen(null);
  const handleOpenDialog = (dialog: T) => () => openDialog(dialog);

  return {
    open,
    load,
    openDialog,
    handleOpenDialog,
    closeDialog,
    handleCloseDialog: closeDialog,
  };
};
