import { MenuRoute, SmartMenu } from '@blb-ventures/react-components';
import { Box } from '@mui/material';
import { AvatarListItem } from '@web/common/components/avatar-list-item';
import { useMeQuery } from 'core/lib/graphql/_gen/me';
import { FC, useMemo } from 'react';

interface ProfileMenuProps {
  onOpenSignIn: () => void;
  onMenuItemClick?: () => void;
}

export const ProfileMenu: FC<ProfileMenuProps> = ({ onOpenSignIn, onMenuItemClick }) => {
  const meQ = useMeQuery();
  const me = useMemo(() => meQ.data?.me, [meQ.data]);
  const profileMenuItems: MenuRoute[] = useMemo(
    () =>
      me != null
        ? [
            { label: 'Meu painel', url: '/admin/subscription', onClick: onMenuItemClick },
            { label: 'Minhas compras', url: '/admin', onClick: onMenuItemClick },
            { label: 'Sair', url: '/logout' },
          ]
        : ([
            { label: 'Entrar', url: '', onClick: onOpenSignIn },
            { label: 'Cliente novo? Cadastrar', url: '/checkout/sign-up?isRegister=1' },
          ] as MenuRoute[]),
    /* eslint-disable react-hooks/exhaustive-deps */
    [me, onOpenSignIn],
  );
  return (
    <>
      <Box px={2} pt={2}>
        <AvatarListItem
          avatar={me?.avatar?.url}
          avatarSize={{ width: '64px', height: '64px' }}
          title={me?.fullName ?? 'Olá, faça seu login'}
          subscriptionStatus={me?.currentSubscription?.status}
          isLogged={me != null}
          disableGutters
        />
      </Box>
      <SmartMenu menuItems={profileMenuItems} hasDivider />
    </>
  );
};
