import styled from '@emotion/styled';
import ReportProblem from '@mui/icons-material/ReportProblem';
import { Button, Paper, Typography } from '@mui/material';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { ImageGallery } from './image-gallery';

export const spacing = (n: number) => `${n * 0.5}rem`;

export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: ${spacing(1)};
  margin-bottom: ${spacing(2)};
  border-bottom: thin solid rgba(0, 0, 0, 0.87);
  h1 {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const ReportProblemIcon = styled(ReportProblem)`
  color: red;
`;

export const Uppercase = styled.span`
  text-transform: uppercase;
`;

export const PositiveButton = styled(Button)`
  color: ${props => props.theme.palette.success.main};
`;

export const WhiteMenuButton = styled(Button)`
  color: white;
`;

export const Logo = styled.div`
  width: 100px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.theme.spacing(1, 0)};
  ${props => props.theme.breakpoints.down('sm')} {
    width: 84px;
    height: 55px;
  }
`;

export const PaperOutlined = styled(Paper)<{ $borderWidth?: string; $borderColor?: string }>`
  border-width: ${props => props.$borderWidth ?? '1px'};
  border-color: ${props => props.$borderColor ?? '7070708e'};
  border-style: solid;
  box-shadow: none !important;
  ${props => props.theme.breakpoints.down('sm')} {
    border-color: transparent;
  }
`;

export const ChangeAnchorScrollPosition = `
  &:before {
    content: '';
    display: block;
    position: relative;
    width: 0;
    height: 5em;
    margin-top: -5em
  }
`;

export const MobileCenteredText = styled(Typography)`
  ${props => props.theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

export const ToolbarProfileImage = styled(OptimizedImage)`
  img {
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
  }
`;

export const TextEllipsis = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ContainerTextEllipsis = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledImageGallery = styled(ImageGallery)`
  display: ${props => (props.items.length > 0 ? undefined : 'none')};
  .image-gallery-slides {
    img {
      width: 100%;
      object-fit: cover;
      object-position: center center;
      border-radius: ${props => props.theme.shape.borderRadius}px;
    }
  }
`;
