export const blogIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.123"
    height="20.123"
    viewBox="0 0 20.123 20.123"
  >
    <path
      id="Icon_ionic-ios-paper"
      data-name="Icon ionic-ios-paper"
      d="M7.051,4.923V20.5a.679.679,0,0,1-.677.677h0A.679.679,0,0,1,5.7,20.5V6.471H4.923A1.546,1.546,0,0,0,3.375,8.019V21.95A1.546,1.546,0,0,0,4.923,23.5H22A1.5,1.5,0,0,0,23.5,22V4.923A1.546,1.546,0,0,0,21.95,3.375L8.5,3.472A1.387,1.387,0,0,0,7.051,4.923Zm3.193,1.548h4.063a.679.679,0,0,1,.677.677h0a.679.679,0,0,1-.677.677H10.244a.679.679,0,0,1-.677-.677h0A.679.679,0,0,1,10.244,6.471Zm0,7.74H17.4a.679.679,0,0,1,.677.677h0a.679.679,0,0,1-.677.677H10.244a.679.679,0,0,1-.677-.677h0A.679.679,0,0,1,10.244,14.21Zm9.481,5.224H10.244a.679.679,0,0,1-.677-.677h0a.679.679,0,0,1,.677-.677h9.481a.679.679,0,0,1,.677.677h0A.679.679,0,0,1,19.725,19.435Zm0-7.74H10.244a.679.679,0,0,1-.677-.677h0a.679.679,0,0,1,.677-.677h9.481a.679.679,0,0,1,.677.677h0A.679.679,0,0,1,19.725,11.7Z"
      transform="translate(-3.375 -3.375)"
      fill="#777"
    />
  </svg>
);
export const produtoresIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.104"
    height="20.124"
    viewBox="0 0 20.104 20.124"
  >
    <g id="layer1" transform="translate(-30.188 -40.136)">
      <path
        id="path39"
        d="M40.489,60.2c-.2-.1-.236-.229-.263-1.039A13.9,13.9,0,0,0,39.146,54l-.108-.243-.176.148a3.309,3.309,0,0,1-2.208.844,5.575,5.575,0,0,1-4.362-2.617,10.859,10.859,0,0,1-2.1-6.014,1.358,1.358,0,0,1,.085-.716,1.3,1.3,0,0,1,.614-.613,1.369,1.369,0,0,1,.673-.081,10.706,10.706,0,0,1,7.025,2.959,5.46,5.46,0,0,1,1.543,2.585,3.166,3.166,0,0,1,.085.877,2.906,2.906,0,0,1-.351,1.518l-.176.357.209.449c.115.247.237.511.27.586.059.132.061.118.088-.472a11.183,11.183,0,0,1,4.157-9.092,14.976,14.976,0,0,1,2.009-1.311c.531-.279.651-.368.712-.531a.476.476,0,0,0-.411-.6,5.967,5.967,0,0,0-1.444.722,11.859,11.859,0,0,0-3.971,3.825,2.94,2.94,0,0,1-.367.487.564.564,0,0,1-.49-.017c-.213-.14-.259-.478-.149-1.106a4.919,4.919,0,0,1,.966-2.153,9.285,9.285,0,0,1,1.487-1.479,10.953,10.953,0,0,1,6.184-2.185c.573,0,.743.051,1.023.331s.335.451.331,1.023a10.685,10.685,0,0,1-1.271,4.743,7.479,7.479,0,0,1-3.842,3.715,3.471,3.471,0,0,1-2.973-.209.939.939,0,0,0-.318-.147,12.5,12.5,0,0,0-.521,2.073,29.328,29.328,0,0,0-.221,5.266c0,3.294.011,3.145-.25,3.269a.377.377,0,0,1-.406,0Z"
        transform="translate(0)"
        fill="#777"
      />
    </g>
  </svg>
);

export const storeIcon = (
  <svg
    id="Group_1480"
    data-name="Group 1480"
    xmlns="http://www.w3.org/2000/svg"
    width="24.462"
    height="24"
    viewBox="0 0 24.462 24"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_804" data-name="Rectangle 804" width="24.462" height="24" fill="none" />
      </clipPath>
    </defs>
    <g id="Group_1479" data-name="Group 1479" clipPath="url(#clip-path)">
      <path
        id="Union_7"
        data-name="Union 7"
        d="M23281.25,22353.5a3.772,3.772,0,0,1-3.857-3.3c-.533-3.016-1.084-6.076-1.619-9.037-.162-.922-.332-1.84-.494-2.76a.05.05,0,0,1-.012-.025.308.308,0,0,1-.021-.045l-.014-.033v-.418l.02-.039a.854.854,0,0,1,.883-.48h.012c.844,0,1.738,0,2.922,0,.967,0,1.943,0,2.916,0h.107v-.982c0-.631,0-1.279.014-1.916a5.237,5.237,0,0,1,3.7-4.76,5.474,5.474,0,0,1,1.451-.2,5.079,5.079,0,0,1,4.674,3.094,5.147,5.147,0,0,1,.457,2.279c0,.553,0,1.113,0,1.66v.826h5.994a.853.853,0,0,1,.7.254.884.884,0,0,1,.123.746c-.18.982-.354,1.963-.529,2.941-.525,2.945-1.072,5.982-1.613,8.977a3.773,3.773,0,0,1-3.836,3.223Zm-3.857-12.076c.51,2.814,1.035,5.717,1.555,8.576a2.163,2.163,0,0,0,2.174,1.92c1.762.008,3.623.008,5.689.008,2.332,0,4.617,0,6.541-.008a2.113,2.113,0,0,0,1.666-.764,2.921,2.921,0,0,0,.572-1.473c.4-2.264.822-4.572,1.229-6.8.154-.838.3-1.672.457-2.51.066-.389.143-.781.209-1.182l.047-.232h-20.582Zm6.25-6.674c-.006.627-.006,1.273,0,1.895v.709h7.193v-.8c0-.646.006-1.32-.012-1.975a3.626,3.626,0,0,0-2.252-3.244,3.75,3.75,0,0,0-1.35-.262A3.624,3.624,0,0,0,23283.643,22334.75Z"
        transform="translate(-23275.004 -22329.498)"
        fill="#383838"
      />
      <path
        id="Path_3435"
        data-name="Path 3435"
        d="M16.011,26.673c0,.286,0,.572,0,.858a.629.629,0,0,1-.6.648.611.611,0,0,1-.606-.634q-.012-.881,0-1.764a.594.594,0,0,1,.591-.616.609.609,0,0,1,.612.625c.007.294,0,.588,0,.882"
        transform="translate(-7.696 -13.086)"
        fill="#ff4f12"
      />
      <path
        id="Path_3436"
        data-name="Path 3436"
        d="M34.877,26.685c0,.286.006.572,0,.858a.614.614,0,0,1-.6.636.627.627,0,0,1-.6-.645q-.009-.87,0-1.74a.608.608,0,0,1,.608-.629.6.6,0,0,1,.595.613c.009.3,0,.6,0,.906"
        transform="translate(-17.506 -13.086)"
        fill="#ff4f12"
      />
    </g>
  </svg>
);

export const supportIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_805" data-name="Rectangle 805" width="24" height="24" fill="none" />
      </clipPath>
    </defs>
    <g id="Group_1481" data-name="Group 1481" transform="translate(0 0)" clipPath="url(#clip-path)">
      <path
        id="Union_8"
        data-name="Union 8"
        d="M23175.863,22352.889a1.174,1.174,0,0,1,.094-.51c.551-1.508,1.143-3.111,1.742-4.705a.43.43,0,0,0-.047-.42,11.862,11.862,0,0,1-1.73-6.912,11.669,11.669,0,0,1,2.58-6.617,11.024,11.024,0,0,1,7.447-4.129,12.086,12.086,0,0,1,8.729,2.277,11.812,11.812,0,0,1,4.371,6.238,11.294,11.294,0,0,1-2.721,11.547,11,11,0,0,1-6.881,3.391c-.346.041-.7.059-.949.076-.094.006-.174.012-.238.018h-.012a12.21,12.21,0,0,1-6.295-1.656.436.436,0,0,0-.229-.074.517.517,0,0,0-.213.057c-1.41.605-2.842,1.215-4.229,1.8l-.334.143a1.007,1.007,0,0,1-.4.086A.657.657,0,0,1,23175.863,22352.889Zm6.568-2.795a10.382,10.382,0,0,0,5.518,1.635,10.982,10.982,0,0,0,2.09-.213,9.9,9.9,0,0,0,6.41-4.268,10.216,10.216,0,0,0,1.461-7.779,10.72,10.72,0,0,0-3.68-6.078,10.588,10.588,0,0,0-6.734-2.449c-.3,0-.609.012-.914.041a9.8,9.8,0,0,0-6.324,2.949,10.216,10.216,0,0,0-1.479,12.334,2.185,2.185,0,0,1,.172,2.23c-.316.725-.58,1.467-.861,2.254-.08.236-.168.477-.252.719.258-.109.521-.219.781-.326.908-.387,1.764-.75,2.627-1.129a1.309,1.309,0,0,1,.535-.125A1.182,1.182,0,0,1,23182.432,22350.094Z"
        transform="translate(-23175.66 -22329.496)"
        fill="#383838"
      />
      <path
        id="Path_3438"
        data-name="Path 3438"
        d="M12.688,14.383a5.073,5.073,0,0,1,.245-.832,3.487,3.487,0,0,1,1.471-1.5c.224-.128.376-.1.518.15.4.692.816,1.37,1.237,2.048a.34.34,0,0,1-.09.52c-.283.222-.559.454-.831.691a1.209,1.209,0,0,0-.4,1.631,6.366,6.366,0,0,0,1.158,1.81,10.454,10.454,0,0,0,2.428,2.171,5.844,5.844,0,0,0,1.105.514,1.031,1.031,0,0,0,1.192-.314c.29-.328.578-.659.851-1a.341.341,0,0,1,.519-.09q1.067.66,2.145,1.3c.186.111.146.246.08.4a2.785,2.785,0,0,1-3.087,1.653,9.7,9.7,0,0,1-4.451-2.194,11.256,11.256,0,0,1-3.769-5.4,15.5,15.5,0,0,1-.32-1.557"
        transform="translate(-6.456 -6.096)"
        fill="#ff4f12"
      />
    </g>
  </svg>
);
