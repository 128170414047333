/* eslint-disable @typescript-eslint/restrict-template-expressions */
import css from '@emotion/css';
import styled from '@emotion/styled';
import { FC, useRef, useState } from 'react';
import ReactImageGallery, { ReactImageGalleryProps } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { showDangerSnackbar } from '../show-snackbar';

interface ImageGalleryContainerProps {
  className?: string;
}

const ImageGalleryContainer = styled.div<{
  fullScreen?: boolean;
}>`
  ${props =>
    props.fullScreen
      ? css`
          position: absolute;
          z-index: 3000;
        `
      : ''}
  .image-gallery-content.fullscreen {
    .image-gallery-slides {
      height: 100vh;
      ${props => props.theme.breakpoints.up('md')} {
        height: calc(100vh - 125px);
        margin-bottom: ${props => props.theme.spacing(1)};
      }
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .image-gallery-slide {
      height: 100%;
    }
    img {
      aspect-ratio: auto;
      height: auto;
      object-fit: contain;
      border-radius: 0;
    }
  }
`;

export const ImageGallery: FC<ImageGalleryContainerProps & ReactImageGalleryProps> = ({
  className,
  ...props
}) => {
  const imageGalleryEl = useRef<ReactImageGallery>(null);
  const [fullScreen, setFullScreen] = useState(false);
  return (
    <ImageGalleryContainer fullScreen={fullScreen} className={className}>
      <ReactImageGallery
        {...props}
        ref={imageGalleryEl}
        onClick={() => {
          if (imageGalleryEl.current != null) {
            try {
              imageGalleryEl.current.fullScreen();
            } catch (err) {
              showDangerSnackbar('Não foi possível abrir galeria em fullscreen');
            }
          }
        }}
        onScreenChange={setFullScreen}
      />
    </ImageGalleryContainer>
  );
};
