import { FC, useRef, ReactNode, ChangeEvent } from 'react';
import { ButtonBase, Typography, CircularProgress } from '@mui/material';
import { useUserAvatarUpdateMutation } from 'core/lib/graphql/user-dashboard/_gen/user-mutations';
import { useMeQuery } from 'core/lib/graphql/_gen/me';
import styled from '@emotion/styled';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { safeAsync } from '../perform-safe-async';

interface UpdateImageProfileProps {
  profileEl: ReactNode;
}

const ProfilePictureContainer = styled.div`
  position: relative;
  width: 4rem;
  height: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.theme.spacing(2, 'auto')};
  &:hover > label {
    opacity: 1;
  }
  & > svg {
    font-size: 1rem;
  }
`;

const Overlay = styled.label`
  transition: opacity ease-in-out 0.2s;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const UpdateImageProfile: FC<UpdateImageProfileProps> = ({ profileEl }) => {
  const meQ = useMeQuery();
  const [updateAvatar, updateAvatarQ] = useUserAvatarUpdateMutation();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const handleFileUploadChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null && e.target.files.length > 0) {
      const id = meQ.data?.me?.id ?? '';
      const newAvatar = e.target.files![0];
      await safeAsync(() => updateAvatar({ variables: { id, avatar: newAvatar } }), {
        successCondition: res => res.data?.userUpdate?.user?.id != null,
        errorMessage: err => err ?? 'Erro ao atualizar foto de perfil',
        successMessage: () => 'Foto de perfil alterada com sucesso!',
      });
    }
  };
  return (
    <ButtonBase
      onClick={() => fileUploadRef.current != null && fileUploadRef.current.click()}
      disabled={updateAvatarQ.loading}
      disableRipple={updateAvatarQ.loading}
    >
      <ProfilePictureContainer>
        {updateAvatarQ.loading ? <CircularProgress size={64} /> : profileEl}
        <Overlay htmlFor="profile-edit-file">
          <PhotoCameraIcon fontSize="small" />
          <Typography variant="body1">Alterar</Typography>
        </Overlay>
      </ProfilePictureContainer>
      <input
        tabIndex={-1}
        type="file"
        onChange={handleFileUploadChange}
        ref={fileUploadRef}
        hidden
      />
    </ButtonBase>
  );
};
