import styled from '@emotion/styled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  AppBar,
  AppBarProps,
  Box,
  Button,
  ButtonProps,
  Container,
  IconButton,
  Link,
  Menu,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useMeQuery } from '@veroo/core/lib/graphql/_gen/me';
import { CartBadgeIcon } from '@web/cart/components/cart-badge-icon';
import { Loader } from '@web/common/components/loaders/loader';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { ToolbarProfileImage } from '@web/common/styles/common';
import { ProfileMenu } from '@web/home/components/profile-menu';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { FC, useMemo, useState } from 'react';
import { blogUrl, contentCenterUrl, knowMore, supportUrl } from '../constants/external-urls';
import { useDialogs } from '../hooks/use-open-dialog';

const LoginDialog = dynamic<any>(
  () => import('@web/authentication/components/dialogs/login-dialog'),
  {
    ssr: true,
    loading: () => <Loader loading />,
  },
);

const TopNavContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

const TopNavSection = styled.div`
  justify-content: flex-start;
  align-items: center;
  display: flex;
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: center;
    flex: 1;
  }
`;

const SignInSection = styled(TopNavSection)`
  justify-content: flex-end;
  flex: 1;
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }

  & > a {
    margin-right: ${props => props.theme.spacing(1)};
  }
`;

const ToolBarMenu = styled.div`
  margin-left: ${props => props.theme.spacing(2)};

  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }

  & > a {
    margin-right: ${props => props.theme.spacing(0.5)};
  }
`;

const MenuButton = styled(Button)<{ active?: boolean }>`
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
`;

const Title = styled.h1`
  margin: 0;
  display: inline;
  font-size: 0;
`;

interface IndexToolbarProps {
  position: AppBarProps['position'];
}

type PublicLayoutDialogs = 'login';

const isActiveRouteGenerator =
  (currentPath: string) =>
  (path: string): boolean => {
    return currentPath.startsWith(path);
  };

interface MenuButtonProps extends Partial<ButtonProps> {
  active?: boolean;
}

const buttonProps: { active: MenuButtonProps; default: MenuButtonProps } = {
  active: { color: 'primary', active: true },
  default: { color: 'inherit' },
};

export const IndexToolbar: FC<IndexToolbarProps> = ({ position }) => {
  const router = useRouter();
  const meQ = useMeQuery();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const dialogs = useDialogs<PublicLayoutDialogs>();

  const [accountMenu, setAccountMenu] = useState<null | HTMLElement>(null);

  const handleProfileClick = (e: any) => {
    setAccountMenu(e.currentTarget);
  };

  const activeRoutes = useMemo(() => {
    const isActiveRoute = isActiveRouteGenerator(router.asPath);
    return {
      index: buttonProps[router.asPath === '/' ? 'active' : 'default'],
      store: buttonProps[isActiveRoute('/loja') ? 'active' : 'default'],
      producers: buttonProps[isActiveRoute('/produtores') ? 'active' : 'default'],
    };
  }, [router.asPath]);

  return (
    <AppBar position={position} color="default" sx={{ boxShadow: 'none' }} variant="elevation">
      <Toolbar disableGutters>
        <TopNavContainer>
          <TopNavSection>
            <Title>
              <NextLink href="/" passHref>
                <Link sx={{ display: 'inline-flex' }}>
                  <OptimizedImage
                    src="/images/logo-veroo.svg"
                    ext="svg"
                    height="32"
                    alt="Veroo - Cafés especiais por assinatura, no conforto da sua casa."
                  />
                </Link>
              </NextLink>
            </Title>
            <ToolBarMenu>
              <NextLink href="/" passHref>
                <MenuButton size="small" {...activeRoutes.index}>
                  Início
                </MenuButton>
              </NextLink>
              <NextLink href="/loja" passHref>
                <MenuButton size="small" {...activeRoutes.store}>
                  Loja
                </MenuButton>
              </NextLink>
              <Link
                underline="none"
                href={knowMore}
                target="_blank"
                rel="nofollow noreferrer"
                color="inherit"
              >
                <MenuButton size="small" color="inherit">
                  Saiba mais
                </MenuButton>
              </Link>
              <Link
                underline="none"
                href={contentCenterUrl}
                target="_blank"
                rel="nofollow noreferrer"
                color="inherit"
              >
                <MenuButton size="small" color="inherit">
                  Central de conteúdos
                </MenuButton>
              </Link>
              <NextLink href="/produtores" passHref>
                <MenuButton size="small" {...activeRoutes.producers}>
                  Produtores
                </MenuButton>
              </NextLink>
              <Link
                underline="none"
                href={blogUrl}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                <MenuButton size="small" color="inherit">
                  Blog
                </MenuButton>
              </Link>
              <Link
                underline="none"
                href={supportUrl}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                <MenuButton size="small" color="inherit">
                  Fale Conosco
                </MenuButton>
              </Link>
            </ToolBarMenu>
          </TopNavSection>
          <SignInSection>
            <NextLink href="/loja/carrinho" passHref>
              <IconButton data-e2e="toolbar-cart-icon-btn">
                <CartBadgeIcon />
              </IconButton>
            </NextLink>
            <div>
              {meQ.data?.me?.avatar != null ? (
                <Button
                  onClick={handleProfileClick}
                  endIcon={<ToolbarProfileImage src={meQ.data.me.avatar.url} />}
                  size="small"
                >
                  {meQ.data.me.fullName?.split(' ')[0]}
                </Button>
              ) : (
                <IconButton onClick={handleProfileClick}>
                  <AccountCircleIcon />
                </IconButton>
              )}
            </div>
          </SignInSection>
        </TopNavContainer>
      </Toolbar>
      {upMd && (
        <Menu
          open={Boolean(accountMenu)}
          anchorEl={accountMenu}
          onClose={() => setAccountMenu(null)}
          MenuListProps={{ disablePadding: true }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Box width={320}>
            <ProfileMenu
              onOpenSignIn={() => {
                dialogs.openDialog('login');
                setAccountMenu(null);
              }}
              onMenuItemClick={() => setAccountMenu(null)}
            />
          </Box>
        </Menu>
      )}
      {dialogs.load.login && (
        <LoginDialog open={dialogs.open === 'login'} onClose={dialogs.handleCloseDialog} />
      )}
    </AppBar>
  );
};
