import { MenuRoute } from '@blb-ventures/react-components';
import styled from '@emotion/styled';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { CartBadgeIcon } from '@web/cart/components/cart-badge-icon';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { supportUrl } from '@web/common/constants/external-urls';
import { UserFieldsFragment } from 'core/lib/graphql/fragments/_gen/user.fragment';
import { Maybe } from 'core/lib/graphql/types.d';
import { SetStateAction } from 'react';
import { ProfileImage } from '../../common/styles/admin';

const NavIcon = styled(OptimizedImage)`
  touch-action: none;
  margin-bottom: ${props => props.theme.spacing(1)};
`;

const CustomMenuIcon = styled(CartBadgeIcon)`
  margin-bottom: ${props => props.theme.spacing(1)};
`;

export const bottomNavRoutes: MenuRoute[] = [
  {
    label: 'Início',
    icon: <NavIcon src="/icons/home.svg" ext="svg" />,
    url: '/',
  },
  {
    label: 'Loja',
    icon: <NavIcon src="/icons/store.svg" ext="svg" />,
    url: '/loja',
  },
  {
    label: 'Contato',
    icon: <NavIcon src="/icons/support.svg" ext="svg" />,
    url: supportUrl,
    external: true,
  },
];

export const getUserAvatarIcon = (me?: Maybe<UserFieldsFragment>) =>
  me?.avatar?.url != null ? (
    <ProfileImage src={me.avatar.url} width="32" height="32" />
  ) : (
    <AccountCircleIcon />
  );

interface GetBottomNavRoutesParams {
  setProfileDrawerVisibility: (value: SetStateAction<boolean>) => void;
}

export const getBottomNavigationRoutes = ({
  setProfileDrawerVisibility,
}: GetBottomNavRoutesParams): MenuRoute[] => [
  ...bottomNavRoutes,
  {
    label: 'Menu',
    icon: (
      <CustomMenuIcon
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24.992"
            height="24"
            viewBox="0 0 24.992 24"
          >
            <g id="Group_1483" data-name="Group 1483" transform="translate(0 0)">
              <rect
                id="Rectangle_806"
                data-name="Rectangle 806"
                width="24.933"
                height="2.361"
                transform="translate(0 0)"
                fill="#383838"
              />
              <rect
                id="Rectangle_807"
                data-name="Rectangle 807"
                width="24.932"
                height="2.361"
                transform="translate(0.06 10.868)"
                fill="#383838"
              />
              <rect
                id="Rectangle_808"
                data-name="Rectangle 808"
                width="24.933"
                height="2.361"
                transform="translate(0 21.639)"
                fill="#383838"
              />
            </g>
          </svg>
        }
      />
    ),
    onClick: () => setProfileDrawerVisibility(true),
  },
];
