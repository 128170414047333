import styled from '@emotion/styled';
import { Badge } from '@mui/material';
import { OptimizedImage } from '@web/common/components/optimized-image';
import { useTotalItemsInCartQuery } from 'core/lib/graphql/cart/_gen/total-products-in-cart';
import { FinalityKind } from 'core/lib/graphql/types.d';
import { FC, useMemo } from 'react';

const NavIcon = styled(OptimizedImage)`
  touch-action: none;
  width: 24px;
  height: 24px;
`;

interface CartBadgeIconProps {
  icon?: any;
  className?: string;
}

const shoppingCartIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs />
    <g transform="translate(0 -10.5)">
      <g transform="translate(15.386 27.942)">
        <g transform="translate(0 0)">
          <path
            style={{ fill: '#777' }}
            d="M295.154,338.862a2.8,2.8,0,1,0,2.8,2.8A2.835,2.835,0,0,0,295.154,338.862Z"
            transform="translate(-292.352 -338.862)"
          />
        </g>
      </g>
      <g transform="translate(0 10.67)">
        <g transform="translate(0 0)">
          <path
            style={{ fill: '#777' }}
            d="M23.119,14.577c-.054,0-.135-.027-.216-.027H5.928l-.269-1.805a2.413,2.413,0,0,0-2.4-2.075H1.078a1.078,1.078,0,1,0,0,2.156H3.26a.287.287,0,0,1,.269.243L5.2,24.439a2.954,2.954,0,0,0,2.91,2.506H19.32a3,3,0,0,0,2.91-2.371l1.751-8.757A1.065,1.065,0,0,0,23.119,14.577Z"
            transform="translate(0 -10.67)"
          />
        </g>
      </g>
      <g transform="translate(5.845 27.942)">
        <g transform="translate(0 0)">
          <path
            style={{ fill: '#777' }}
            d="M116.542,341.53a2.786,2.786,0,0,0-2.775-2.668,2.818,2.818,0,0,0-2.695,2.91,2.762,2.762,0,0,0,2.748,2.668h.054A2.8,2.8,0,0,0,116.542,341.53Z"
            transform="translate(-111.07 -338.862)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const CartBadgeIcon: FC<CartBadgeIconProps> = ({ icon = shoppingCartIcon, className }) => {
  const myCartItemsQ = useTotalItemsInCartQuery({
    variables: { finality: FinalityKind.Ecommerce },
    fetchPolicy: 'cache-and-network',
  });
  const itemsInCart = useMemo(() => myCartItemsQ.data?.myCart?.itemsNumber ?? 0, [myCartItemsQ]);
  const iconEl = useMemo(
    () => <NavIcon src={icon} ext="svg" include className={className} />,
    [icon, className],
  );
  return (itemsInCart ?? 0) > 0 ? (
    <Badge badgeContent={itemsInCart} color="error" data-e2e="cart-badge-total-items">
      {iconEl}
    </Badge>
  ) : (
    iconEl
  );
};
