import styled from '@emotion/styled';
import PersonIcon from '@mui/icons-material/Person';
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import { ProfileImage } from '@web/common/styles/admin';
import { theme } from '@web/common/theme';
import { SubscriptionStatus } from 'core/lib/graphql/types.d';
import NextLink from 'next/link';
import { FC, MouseEventHandler, ReactNode, useMemo } from 'react';
import { UpdateImageProfile } from './update-image-profile';

type Action<T> = T | string;

type CustomListItemProps = Omit<ListItemProps, 'title'>;
type ExtendedSubscriptionStatus = SubscriptionStatus | 'pending';

const dotStatusColors: Record<ExtendedSubscriptionStatus, string> = {
  [SubscriptionStatus.Active]: theme.palette.success.main,
  [SubscriptionStatus.Paused]: theme.palette.warning.main,
  [SubscriptionStatus.Canceled]: theme.palette.error.main,
  pending: theme.palette.warning.main,
};

const subscriptionStatusKind: Record<ExtendedSubscriptionStatus, string> = {
  [SubscriptionStatus.Active]: 'Ativa',
  [SubscriptionStatus.Paused]: 'Pausada',
  [SubscriptionStatus.Canceled]: 'Cancelada',
  pending: 'Pendente',
};

const StyledDefaultImage = styled.div<{ width?: string; height?: string }>`
  width: ${props => props.width ?? '64px'};
  height: ${props => props.height ?? '64px'};
  background-color: #777;
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dot = styled.div<{ status: ExtendedSubscriptionStatus }>`
  height: ${props => props.theme.spacing(1)};
  width: ${props => props.theme.spacing(1)};
  background-color: ${props => dotStatusColors[props.status]};
  border-radius: 50%;
`;

interface AvatarListItemProps extends CustomListItemProps {
  avatar?: string;
  avatarSize?: {
    width?: string;
    height?: string;
  };
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  subscriptionStatus?: SubscriptionStatus | null;
  endIcon?: ReactNode;
  endIconOnClick?: Action<() => void>;
  isButton?: boolean;
  canChangeAvatar?: boolean;
  isLogged?: boolean;
  isPendingPayment?: boolean;
}

export const AvatarListItem: FC<AvatarListItemProps> = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  subscriptionStatus,
  endIcon,
  endIconOnClick,
  isButton = false,
  canChangeAvatar = false,
  isLogged = false,
  isPendingPayment,
  ...rest
}) => {
  const isEndBtnFunction = typeof endIconOnClick === 'function';
  const action = isEndBtnFunction ? (endIconOnClick as MouseEventHandler<unknown>) : undefined;
  const IconBtn = <IconButton onClick={action}>{endIcon}</IconButton>;
  const extendedSubscriptionStatus: ExtendedSubscriptionStatus = isPendingPayment
    ? 'pending'
    : subscriptionStatus ?? 'pending';

  const profileImageEl = useMemo(
    () =>
      avatar != null ? (
        <ProfileImage src={avatar} width={avatarSize?.width} height={avatarSize?.height} />
      ) : (
        <StyledDefaultImage width={avatarSize?.width} height={avatarSize?.height}>
          <PersonIcon fontSize="large" />
        </StyledDefaultImage>
      ),
    [avatar, avatarSize?.height, avatarSize?.width],
  );
  const subtitleEl = useMemo(
    () =>
      subtitle ||
      (subscriptionStatus ? (
        <Box display="flex" alignItems="center" gap={1}>
          <Dot status={extendedSubscriptionStatus} />
          <Typography>{`Assinatura ${subscriptionStatusKind[extendedSubscriptionStatus]}`}</Typography>
        </Box>
      ) : isLogged ? (
        ''
      ) : (
        'ou cadastre-se'
      )),
    [isLogged, extendedSubscriptionStatus, subscriptionStatus, subtitle],
  );

  return (
    <ListItem {...rest} button={isButton as any} onClick={isButton ? action : undefined}>
      <ListItemAvatar>
        <Box mr={2}>
          {canChangeAvatar ? <UpdateImageProfile profileEl={profileImageEl} /> : profileImageEl}
        </Box>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: 'subtitle1',
        }}
        secondary={subtitleEl}
      />
      {endIcon != null && endIconOnClick != null && (
        <ListItemSecondaryAction>
          {isEndBtnFunction ? (
            IconBtn
          ) : (
            <NextLink href={endIconOnClick as string} passHref>
              {IconBtn}
            </NextLink>
          )}
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};
