import { FC, ReactNode, useState, useMemo } from 'react';
import { useDialogs } from '@web/common/hooks/use-open-dialog';
import { BottomNav } from '@web/common/components/navigation/bottom-nav';
import { getBottomNavigationRoutes } from '@web/home/components/bottom-nav-routes';
import { IndexSidebarMenu } from '@web/home/components/sidebar-menu';
import dynamic from 'next/dynamic';
import { Loader } from '@web/common/components/loaders/loader';
import styled from '@emotion/styled';

const LoginDialog = dynamic<any>(
  () => import('@web/authentication/components/dialogs/login-dialog'),
  {
    ssr: true,
    loading: () => <Loader loading />,
  },
);

export const MobileNav = styled.nav`
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 800;
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`;

interface IndexBottomNavProps {
  hasBottomNav?: boolean;
  bottomNavChildren?: ReactNode;
}

type PublicLayoutDialogs = 'login';

export const IndexBottomNav: FC<IndexBottomNavProps> = ({
  bottomNavChildren,
  hasBottomNav = true,
}) => {
  const dialogs = useDialogs<PublicLayoutDialogs>();
  const [openSidebarDrawer, setOpenSidebarDrawer] = useState(false);

  const bottomNavigationRoutes = useMemo(
    () => getBottomNavigationRoutes({ setProfileDrawerVisibility: setOpenSidebarDrawer }),
    [setOpenSidebarDrawer],
  );

  const handleOpenSignIn = () => {
    dialogs.openDialog('login');
    setOpenSidebarDrawer(false);
  };

  return (
    <MobileNav>
      {bottomNavChildren}
      {hasBottomNav && (
        <>
          <BottomNav
            routes={bottomNavigationRoutes}
            hasShadow={bottomNavChildren == null}
            showLabel
          />
          <IndexSidebarMenu
            onClose={() => setOpenSidebarDrawer(false)}
            open={openSidebarDrawer}
            onOpenSignIn={handleOpenSignIn}
          />
          {dialogs.load.login && (
            <LoginDialog open={dialogs.open === 'login'} onClose={dialogs.handleCloseDialog} />
          )}
        </>
      )}
    </MobileNav>
  );
};
